import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  EgeCacheClusterH3PBFResponse,
  EgeCacheClusterH3Response,
  EgeCacheClusterH3SummarizedPBFResponse,
  EgeCacheClusterH3SummarizedResponse,
  EGECacheClusterRequest,
  EGECacheRequest,
  EGEEntityRequest,
  EGEH3CachePBFResponse,
  EGEH3CacheResponseRow,
  EGESlimCachePBFResponse,
  EGESlimCacheResponse
} from '@types';
import pbf from './pbf';
import { sec } from './security';
import dayjs from 'dayjs';

const { VITE_API_HOST } = import.meta.env;

const pbfResponseHandler = async <T, J>(response: Response, def: string) => {
  if (response.headers.get('content-type') === 'application/x-protobuf') {
    if (!pbf.root) throw new Error('illogical: pbf root undefined');
    // convert to buffer
    const data = new Uint8Array(await response.arrayBuffer());
    // load type from pbf
    const wifiLocalizedSummarizedResponse = pbf.root.lookupType(def);
    // decode response
    const decoded = wifiLocalizedSummarizedResponse.decode(data) as unknown as T;
    // return subset rows:
    return decoded;
  } else {
    return response.json() as unknown as J;
  }
};

// Define a service using a base URL and expected endpoints
export const api = createApi({
  tagTypes: [],
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: VITE_API_HOST,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) headers.set('Authorization', `Bearer ${access_token}`);
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    // getEGESummary: builder.query<RowboatSummaryResponse, EGECacheSummaryRequest>({
    //   // don't cache data... it's too big!
    //   keepUnusedDataFor: 1,
    //   query: ({ rowboat_table_identifier, advertiser_ids }) => {
    //     return {
    //       method: 'POST',
    //       url: `/ege/cache/${rowboat_table_identifier}`,
    //       heads: {
    //         Accept: 'application/json'
    //       },
    //       body: {
    //         advertiser_ids: advertiser_ids ? advertiser_ids.join(';') : undefined,
    //         min_date: '2023-01-01',
    //         summary: true
    //       }
    //     };
    //   }
    // }),
    getEGEEntities: builder.query<[string, string][], EGEEntityRequest>({
      query: ({ rowboat_table_identifier, bounds }) => {
        return {
          method: 'POST',
          url: `/ege/cache/${rowboat_table_identifier}`,
          heads: {
            Accept: 'application/json'
          },
          body: {
            bounds,
            entities_only: true
          }
        };
      }
    }),
    getEGEData: builder.query<EGESlimCacheResponse[], EGECacheRequest>({
      // don't cache data... it's too big!
      keepUnusedDataFor: 1,
      query: ({ rowboat_table_identifier, bounds, advertiser_ids }) => {
        return {
          method: 'POST',
          url: `/ege/cache/${rowboat_table_identifier}`,
          headers: {
            Accept: 'application/x-protobuf'
          },
          body: {
            slim: true,
            bounds,
            advertiser_ids: advertiser_ids ? advertiser_ids.join(';') : undefined,
            min_date: '2023-01-01'
          },
          responseHandler: async (response: Response): Promise<EGESlimCacheResponse[]> => {
            const r = await pbfResponseHandler<EGESlimCachePBFResponse, EGESlimCachePBFResponse>(response, 'basicserver.EgeCacheSlimResponse');
            // [!] Annoying:
            // PBF formats cannot be stored in the cache, so we need to return a mapped value to generic JSON
            return r.rows.map((row) => ({
              advertiser_id: row.advertiserId,
              record_created_time: row.recordCreatedTime,
              latitude: row.latitude,
              longitude: row.longitude,
              data_source: row.dataSource
            }));
          }
        };
      }
    }),
    getEGEH3Data: builder.query<EGEH3CachePBFResponse, EGECacheRequest>({
      // don't cache data... it's too big!
      keepUnusedDataFor: 1,
      query: ({ rowboat_table_identifier, bounds, advertiser_ids, hexDimension, hexSize }) => {
        return {
          method: 'POST',
          url: `/ege/cache/${rowboat_table_identifier}`,
          headers: {
            Accept: 'application/x-protobuf'
          },
          body: {
            slim: false,
            bounds,
            advertiser_ids: advertiser_ids ? advertiser_ids.join(';') : undefined,
            min_date: '2023-01-01',
            agg: true,
            h3_size: hexSize || 1,
            h3_dimension: hexDimension || 'count'
          },
          responseHandler: async (response: Response): Promise<EGEH3CachePBFResponse> => {
            const r = await pbfResponseHandler<EGEH3CachePBFResponse, EGEH3CacheResponseRow[]>(response, 'basicserver.EgeCacheH3Response');
            // this should never happen, but lets care for it; it should match the response shape of the
            // PBF response:
            if (Array.isArray(r)) {
              console.warn('unexpected response shape, expected PBF response');
              return {
                rows: r.map((row) => ({
                  index: row.h3_index,
                  count: row.h3_count
                }))
              };
            } else {
              // [!] Annoying:
              // PBF formats cannot be stored in the cache, so we need to return a mapped value to generic JSON
              return {
                rows: r.rows.map((row) => ({
                  index: row.index,
                  count: row.count
                }))
              };
            }
          }
        };
      }
    }),
    getDataCancel: builder.mutation<void, string>({
      query: (request_identifier) => ({
        method: 'DELETE',
        url: '/',
        params: {
          request_identifier
        }
      })
    }),
    getEGEH3ClusterData: builder.query<EgeCacheClusterH3Response, EGECacheClusterRequest>({
      // don't cache data... it's too big!
      keepUnusedDataFor: 1,
      query: ({ cluster_identifier, advertiser_ids, bounds, diameter, limit }) => {
        return {
          method: 'POST',
          url: `/ege/cache/cluster/${cluster_identifier}`,
          headers: {
            Accept: 'application/x-protobuf'
          },
          body: {
            summary: false,
            advertiser_ids: advertiser_ids ? advertiser_ids.join(';') : undefined,
            bounds,
            diameter,
            limit
          },
          responseHandler: async (response: Response): Promise<EgeCacheClusterH3Response> => {
            const resp = await pbfResponseHandler<EgeCacheClusterH3PBFResponse, EgeCacheClusterH3PBFResponse>(response, 'basicserver.EgeCacheClusterH3Response');

            return {
              rows: resp.rows.map((row) => ({
                latitude: row.latitude,
                longitude: row.longitude,
                advertiser_id_count: row.advertiserIdCount,
                minhour: row.minhour,
                maxhour: row.maxhour,
                hourcount: row.hourcount,
                h3_index: row.h3Index,
                theday: row.theday,
                advertiser_ids: row.advertiserIds,
                hourlist: row.hourlist
              }))
            };
          }
        };
      }
    }),
    getEGEH3ClusterSummarizedData: builder.query<EgeCacheClusterH3SummarizedResponse, EGECacheClusterRequest>({
      // don't cache data... it's too big!
      keepUnusedDataFor: 1,
      query: ({ cluster_identifier, advertiser_ids, bounds, diameter, limit }) => {
        return {
          method: 'POST',
          url: `/ege/cache/cluster/${cluster_identifier}`,
          headers: {
            Accept: 'application/x-protobuf'
          },
          body: {
            summary: true,
            advertiser_ids: advertiser_ids ? advertiser_ids.join(';') : undefined,
            bounds,
            diameter,
            limit
          },
          responseHandler: async (response: Response): Promise<EgeCacheClusterH3SummarizedResponse> => {
            const resp = await pbfResponseHandler<EgeCacheClusterH3SummarizedPBFResponse, EgeCacheClusterH3SummarizedPBFResponse>(
              response,
              'basicserver.EgeCacheClusterH3SummarizedResponse'
            );

            return {
              rows: resp.rows.map((row) => ({
                advertiser_ids: row.advertiserIds,
                advertiser_id_count: row.advertiserIdCount,
                thedays: row.thedays,
                h3_index: row.h3Index,
                theepochs: row.thedays.map((d) => dayjs(d).valueOf())
              }))
            };
          }
        };
      }
    })
  })
});

export const {
  // \n
  useLazyGetEGEEntitiesQuery,
  useGetDataCancelMutation,
  useGetEGEDataQuery,
  useGetEGEH3DataQuery,
  useGetEGEH3ClusterDataQuery,
  useGetEGEH3ClusterSummarizedDataQuery
} = api;
