import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MetaSearchResult } from '@stats/types/service';
import { sec } from './security';
import {
  AlertGroupsByDayResponse,
  AlertGroupsByDayResponseEx,
  AlertsByDayResponse,
  AlertsByDayResponseEx,
  AlertsDailyHitsResponse,
  AlertsGetClustersRequest,
  Audience,
  AudienceBookmark,
  AudienceRelationsRequest,
  AudienceRequest,
  CreateCampaignRequest,
  CreateNewAlertRequest,
  EntityNote,
  FootfallHistogramResponse,
  GADMCountry,
  GetAlertsResponse,
  GetCampaignResponse,
  GetCampaignTimelineRequest,
  GetCampaignTimelineResponse,
  GetEntityDwellForLocationsRequest,
  GetEntityDwellForLocationsResponse,
  GetEntityScoresRequest,
  GetEntityScoresResponse,
  GetIconReturn,
  GetLocationNetworkResponse,
  GetLocationsRequest,
  GetLocationsResponse,
  GetNewsRequest,
  GetNewsResponse,
  GetTackleboxHistoryResponse,
  LocationBookmark,
  LocationDailyVisitsResponse,
  LocationVisitorsDailyRequest,
  PolygonNote,
  SaveAudienceBookmarkRequest,
  SaveEntityNoteRequest,
  SaveIconRequest,
  SaveLocationBookmarkRequest,
  SaveLocationNoteRequest,
  SaveStateRequest,
  SavedState,
  TitoRequest,
  UpdateAlertRequest,
  User,
  UserNotification
} from './types';
import { TackleboxJobRespone, TouchstoneStatsByCategory } from './Views/Tacklebox/types';

const { VITE_SERVICE_HOST } = import.meta.env;

// Define a service using a base URL and expected endpoints
export const service = createApi({
  tagTypes: [
    //n
    'state_hashes',
    'polygon_icon',
    'location_note',
    'entity_note',
    'user_notification',
    'location_bookmarks',
    'audience_bookmarks',
    'alerts',
    'tacklebox_history',
    'campaigns'
  ],
  reducerPath: 'service',
  baseQuery: fetchBaseQuery({
    baseUrl: VITE_SERVICE_HOST,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) headers.set('Authorization', `Bearer ${access_token}`);
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getContextualSearchResults: builder.query<MetaSearchResult[], string>({
      query: (term) => ({
        url: `/v0/meta/search/${term}`,
        method: 'GET'
      })
    }),
    getBoundsForEntities: builder.mutation<void, string[]>({
      query: (entity_ids) => ({
        method: 'POST',
        url: '/v0/audience/bounds',
        body: {
          entity_ids
        }
      })
    }),
    getUser: builder.query<User, void>({
      query: () => '/v0/user'
    }),
    saveState: builder.mutation<{ hash: string }, SaveStateRequest>({
      invalidatesTags: ['state_hashes'],
      query: (body: SaveStateRequest) => ({
        method: 'POST',
        url: '/v0/state/save',
        body
      })
    }),
    updateSavedState: builder.mutation<void, { hash: string; name: string }>({
      invalidatesTags: ['state_hashes'],
      query: ({ hash, name }) => ({
        method: 'PUT',
        url: `/v0/state/${hash}`,
        body: { name }
      })
    }),
    deleteSavedState: builder.mutation<void, string>({
      invalidatesTags: ['state_hashes'],
      query: (hash) => ({
        method: 'DELETE',
        url: `/v0/state/${hash}`
      })
    }),
    getSavedStates: builder.query<SavedState[], void>({
      providesTags: ['state_hashes'],
      query: () => ({
        method: 'GET',
        url: `/v0/state`
      })
    }),
    // creating some cyclical dependencies here.. using `any` here to avoid it
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getSavedState: builder.query<any, string>({
      query: (hash) => ({
        method: 'GET',
        url: `/v0/state/fetch/${hash}`
      })
    }),
    getIcon: builder.query<GetIconReturn, string>({
      providesTags: ['polygon_icon'],
      query: (polygon_id) => ({
        method: 'GET',
        url: `/v0/location/icon/${polygon_id}`
      })
    }),
    saveIcon: builder.mutation<void, SaveIconRequest>({
      invalidatesTags: ['polygon_icon'],
      query: ({ form, polygon_id }) => ({
        method: 'POST',
        url: '/v0/location/icon',
        body: form,
        params: { polygon_id }
      })
    }),
    getLocationNotes: builder.query<PolygonNote[], string>({
      providesTags: ['location_note'],
      query: (polygon_id) => ({
        method: 'GET',
        url: `/v0/location/notes/${polygon_id}`
      })
    }),
    getLocationNetwork: builder.query<GetLocationNetworkResponse[], string>({
      query: (polygon_id) => ({
        method: 'GET',
        url: `/v0/location/network/${polygon_id}`
      })
    }),
    saveLocationNote: builder.mutation<void, SaveLocationNoteRequest>({
      invalidatesTags: ['location_note'],
      query: ({ note, polygon_id }) => ({
        method: 'POST',
        url: '/v0/location/note',
        params: { polygon_id, note }
      })
    }),
    deleteLocationNote: builder.mutation<void, string>({
      invalidatesTags: ['location_note'],
      query: (note_id) => ({
        method: 'DELETE',
        url: `/v0/location/note/${note_id}`
      })
    }),
    getEntityNotes: builder.query<EntityNote[], string>({
      providesTags: ['entity_note'],
      query: (entity_id) => ({
        method: 'GET',
        url: `/v0/audience/notes/${entity_id}`
      })
    }),
    saveEntityNote: builder.mutation<void, SaveEntityNoteRequest>({
      invalidatesTags: ['entity_note'],
      query: ({ note, entity_id }) => ({
        method: 'POST',
        url: '/v0/audience/note',
        params: { entity_id, note }
      })
    }),
    deleteEntityNote: builder.mutation<void, string>({
      invalidatesTags: ['entity_note'],
      query: (note_id) => ({
        method: 'DELETE',
        url: `/v0/audience/note/${note_id}`
      })
    }),
    getNotifications: builder.query<UserNotification[], void>({
      providesTags: ['user_notification'],
      query: () => ({
        method: 'GET',
        url: `/v0/user/notifications`
      })
    }),
    deleteNotification: builder.mutation<void, string>({
      invalidatesTags: ['user_notification'],
      query: (notification_id) => ({
        method: 'DELETE',
        url: `/v0/user/notification/${notification_id}`
      })
    }),
    seenNotification: builder.mutation<void, void>({
      invalidatesTags: ['user_notification'],
      query: () => ({
        method: 'PUT',
        url: '/v0/user/notification/seen'
      })
    }),
    getLocations: builder.query<GetLocationsResponse[], GetLocationsRequest>({
      query: ({ advertiser_ids, bounding_box, countries, polygon_ids, sort, categories, min_unique_entities, name, campaign }) => ({
        method: 'POST',
        url: '/v0/location' + (campaign ? `/campaign/${campaign.id}` : ''),
        body: {
          advertiser_ids: advertiser_ids ? advertiser_ids.split(',') : undefined,
          bounding_box,
          countries: countries && countries?.length > 0 ? countries.map((d) => d.iso3) : undefined,
          polygon_ids,
          categories: categories && categories.length > 0 ? categories : undefined,
          sort,
          min_unique_entities,
          name
        }
      })
    }),
    getLocationVisitorsDaily: builder.query<LocationDailyVisitsResponse[], LocationVisitorsDailyRequest>({
      query: ({ polygon_id, date_range }) => ({
        url: `/v0/location/daily-visits`,
        method: 'POST',
        body: {
          date_range,
          polygon_id
        }
      })
    }),
    getAudience: builder.query<Audience[], AudienceRequest>({
      query: ({ polygon_ids, audience_ids, min_polygon_interaction, sort, min_score, campaign }) => ({
        method: 'POST',
        url: '/v0/audience' + (campaign ? `/campaign/${campaign.id}` : ''),
        body: {
          polygon_ids: polygon_ids && polygon_ids.length > 0 ? polygon_ids : undefined,
          audience_ids: audience_ids && audience_ids.length > 0 ? audience_ids : undefined,
          min_polygon_interaction: (min_polygon_interaction ?? 0) > 0 ? min_polygon_interaction : undefined,
          min_score,
          sort
        }
      })
    }),
    getAudienceRelations: builder.query<{ entities: Audience[]; polygons: GetLocationsResponse[] }, AudienceRelationsRequest>({
      query: ({ entities, campaign }) => ({
        method: 'POST',
        url: `/v0/audience/campaign/${campaign.id}/entities`,
        body: entities
      })
    }),
    getLocation: builder.mutation<GetLocationsResponse[], string>({
      query: (polygon_id) => ({
        method: 'POST',
        url: `/v0/location`,
        body: {
          polygon_ids: [polygon_id]
        }
      })
    }),
    getEntity: builder.mutation<Audience, string>({
      query: (entity_id) => ({
        method: 'POST',
        url: `/v0/audience`,
        body: {
          audience_ids: [entity_id]
        }
      })
    }),
    getFootfallHistogramForProject: builder.query<FootfallHistogramResponse, string>({
      query: (project_id) => ({
        url: `/v0/project/footfall/${project_id}`,
        method: 'GET'
      })
    }),
    getCategoriesForProject: builder.query<string[], string>({
      query: (project_id) => ({
        url: `/v0/project/categories/${project_id}`,
        method: 'GET'
      })
    }),
    getLocationBookmarks: builder.query<LocationBookmark[], string>({
      providesTags: ['location_bookmarks'],
      query: (project_id) => ({
        method: 'GET',
        url: `/v0/location/bookmarks/${project_id}`
      })
    }),
    saveLocationBookmark: builder.mutation<void, SaveLocationBookmarkRequest>({
      invalidatesTags: ['location_bookmarks'],
      query: ({ bookmark_name, polygon_ids, project_id }) => ({
        method: 'POST',
        url: '/v0/location/bookmark',
        body: { bookmark_name, polygon_ids, project_id }
      })
    }),
    deleteLocationBookmark: builder.mutation<void, string>({
      invalidatesTags: ['location_bookmarks'],
      query: (bookmark_id) => ({
        method: 'DELETE',
        url: `/v0/location/bookmark/${bookmark_id}`
      })
    }),
    getAudienceBookmarks: builder.query<AudienceBookmark[], string>({
      providesTags: ['audience_bookmarks'],
      query: (project_id) => ({
        method: 'GET',
        url: `/v0/audience/bookmarks/${project_id}`
      })
    }),
    saveAudienceBookmark: builder.mutation<void, SaveAudienceBookmarkRequest>({
      invalidatesTags: ['audience_bookmarks'],
      query: ({ bookmark_name, advertiser_ids, project_id }) => ({
        method: 'POST',
        url: '/v0/audience/bookmark',
        body: { bookmark_name, advertiser_ids, project_id }
      })
    }),
    deleteAudienceBookmark: builder.mutation<void, string>({
      invalidatesTags: ['audience_bookmarks'],
      query: (bookmark_id) => ({
        method: 'DELETE',
        url: `/v0/audience/bookmark/${bookmark_id}`
      })
    }),
    deleteAlert: builder.mutation<void, string>({
      invalidatesTags: ['alerts'],
      query: (alert_id) => ({
        method: 'DELETE',
        url: `/v0/alert/${alert_id}`
      })
    }),
    createNewAlert: builder.mutation<{ id: string } | { error: string }, CreateNewAlertRequest>({
      invalidatesTags: ['alerts'],
      query: (body) => ({
        method: 'POST',
        url: '/v0/alert',
        body
      })
    }),
    updateAlert: builder.mutation<void, UpdateAlertRequest>({
      invalidatesTags: ['alerts'],
      query: (body) => ({
        method: 'PUT',
        url: `/v0/alert`,
        body
      })
    }),
    getExistingAlerts: builder.query<GetAlertsResponse[], string>({
      providesTags: ['alerts'],
      query: (project_id) => ({
        method: 'POST',
        url: '/v0/alert/existing',
        body: {
          project_id
        }
      })
    }),
    getAlertById: builder.query<GetAlertsResponse, { alert_id: string }>({
      providesTags: ['alerts'],
      query: ({ alert_id }) => ({
        method: 'POST',
        url: `/v0/alert/existing/${alert_id}`,
        body: {
          alert_id
        }
      })
    }),
    getDateHistogramForAlert: builder.query<AlertsDailyHitsResponse[], string>({
      query: (alert_id) => ({
        method: 'GET',
        url: `/v0/alert/${alert_id}/daily_hits`
      })
    }),
    getClustersForAlert: builder.query<AlertsByDayResponseEx[], AlertsGetClustersRequest>({
      query: ({ alert_id, date_range, bounding_box, audience }) => ({
        method: 'POST',
        url: `/v0/alert/clusters`,
        body: {
          alert_id,
          start_date: date_range && date_range.length > 0 ? date_range[0] : undefined,
          end_date: date_range && date_range.length > 1 ? date_range[1] : undefined,
          bounding_box,
          audience
        }
      }),
      transformResponse: (response: AlertsByDayResponse[]) => {
        const output: AlertsByDayResponseEx[] = [];
        for (const alert of response) {
          output.push({
            id: alert.id,
            alert_id: alert.alert_id,
            id_list: alert.id_list,
            hour_list: alert.hour_list,
            day: alert.day,
            lon: alert.lon,
            lat: alert.lat,
            num_ids: alert.id_list.length,
            num_hours: alert.hour_list.length,
            country: alert.country
          });
        }
        return output;
      }
    }),
    getClusterGroupsForAlert: builder.query<AlertGroupsByDayResponseEx[], AlertsGetClustersRequest>({
      query: ({ alert_id, date_range, bounding_box, audience }) => ({
        method: 'POST',
        url: `/v0/alert/clusters`,
        body: {
          alert_id,
          start_date: date_range && date_range.length > 0 ? date_range[0] : undefined,
          end_date: date_range && date_range.length > 1 ? date_range[1] : undefined,
          bounding_box,
          group_by_day: true,
          audience
        }
      }),
      transformResponse: (response: AlertGroupsByDayResponse[]) => {
        const output: AlertGroupsByDayResponseEx[] = [];
        for (const alert of response) {
          output.push({
            id: [alert.lon, alert.lat].join(),
            first_seen: alert.first_seen,
            last_seen: alert.last_seen,
            alert_days: alert.alert_days,
            lon: alert.lon,
            lat: alert.lat,
            entity_ids: alert.entity_ids,
            num_days: alert.alert_days.length,
            num_entities: alert.entity_ids.length,
            country: alert.country
          });
        }
        return output;
      }
    }),
    getNews: builder.query<GetNewsResponse[], GetNewsRequest>({
      query: ({ bounding_box, project_id }) => ({
        method: 'POST',
        url: '/v0/news',
        body: {
          bounding_box,
          project_id
        }
      })
    }),
    getGADMCountries: builder.query<GADMCountry[], void>({
      query: () => ({
        method: 'GET',
        url: '/v0/meta/gadm'
      })
    }),
    getEntityDwellForLocations: builder.query<GetEntityDwellForLocationsResponse[], GetEntityDwellForLocationsRequest>({
      query: ({ entity_id, polygon_ids, date_range }) => ({
        method: 'POST',
        url: `/v0/audience/metrics`,
        body: {
          polygon_ids,
          entity_id,
          date_range
        }
      })
    }),
    getEntityScores: builder.query<GetEntityScoresResponse[], GetEntityScoresRequest>({
      query: ({ entity_id, polygon_ids }) => ({
        method: 'POST',
        url: `/v0/audience/scores`,
        body: {
          polygon_ids,
          entity_id
        }
      })
    }),
    getCampaignTimeline: builder.query<GetCampaignTimelineResponse[], GetCampaignTimelineRequest>({
      query: ({ campaign_id, entity_ids }) => ({
        method: 'POST',
        url: `/v0/campaign/${campaign_id}/timeline`,
        body: {
          entity_ids
        }
      })
    }),
    deleteCampaign: builder.mutation<void, string>({
      invalidatesTags: ['campaigns'],
      query: (campaign_id) => ({
        method: 'DELETE',
        url: `/v0/campaign/${campaign_id}`
      })
    }),
    toggleCampaignEnabled: builder.mutation<void, string>({
      invalidatesTags: ['campaigns'],
      query: (campaign_id) => ({
        method: 'PUT',
        url: `/v0/campaign/${campaign_id}/enabled`
      })
    }),
    getCampaigns: builder.query<GetCampaignResponse[], void>({
      providesTags: ['campaigns'],
      query: () => ({
        method: 'GET',
        url: '/v0/campaign'
      })
    }),
    getCampaign: builder.query<GetCampaignResponse, string>({
      providesTags: ['campaigns'],
      query: (id) => ({
        method: 'GET',
        url: `/v0/campaign/${id}`
      })
    }),
    createCampaign: builder.mutation<{ id: string }, CreateCampaignRequest>({
      invalidatesTags: ['campaigns'],
      query: ({ name, ifChoice, pairs, toolConfig }) => ({
        method: 'POST',
        url: '/v0/campaign',
        body: {
          name,
          if: {
            item: ifChoice.item,
            meta: ifChoice.meta,
            selection: ifChoice.selection
          },
          pairs: pairs.map((pair) => ({
            subject: {
              item: pair.subject.item,
              meta: pair.subject.meta,
              selection: pair.subject.selection
            },
            condition: {
              item: pair.condition.item,
              meta: pair.condition.meta,
              selection: pair.condition.selection,
              secondarySelection: pair.condition.secondarySelection
            }
          })),
          toolConfig
        }
      })
    }),
    titoRequest: builder.mutation<void, TitoRequest>({
      invalidatesTags: ['tacklebox_history'],
      query: ({ name, features, minPolygons }) => ({
        method: 'POST',
        url: '/v0/tacklebox/tito',
        body: {
          name,
          features,
          minPolygons
        }
      })
    }),
    getTackleboxHistory: builder.query<GetTackleboxHistoryResponse[], { all_data: boolean }>({
      providesTags: ['tacklebox_history'],
      query: ({ all_data }) => ({
        method: 'GET',
        url: `/v0/tacklebox/history?all_data=${all_data.valueOf()}`
      })
    }),
    deleteTackleboxHistory: builder.mutation<void, string>({
      invalidatesTags: ['tacklebox_history'],
      query: (id) => ({
        method: 'DELETE',
        url: `/v0/tacklebox/history/${id}`
      })
    }),
    getTackleboxJob: builder.query<TackleboxJobRespone, { job_id: string }>({
      query: ({ job_id }) => ({
        method: 'GET',
        url: `/v0/tacklebox/job/${job_id}`
      })
    }),
    getTouchstoneStats: builder.query<TouchstoneStatsByCategory, void>({
      query: () => ({
        method: 'GET',
        url: '/v0/stats/touchstone'
      })
    }),
    getSignedUrl: builder.query<{ url: string }, string>({
      query: (jobId) => ({
        method: 'GET',
        url: `v0/tacklebox/download/csv/${jobId}`
      })
    })
  })
});

export const {
  useLazyGetAudienceRelationsQuery,
  useGetCampaignTimelineQuery,
  useLazyGetAudienceQuery,
  useLazyGetLocationsQuery,
  useGetContextualSearchResultsQuery,
  useGetGADMCountriesQuery,
  useGetNewsQuery,
  useGetClusterGroupsForAlertQuery,
  useGetAlertByIdQuery,
  useGetCategoriesForProjectQuery,
  useGetUserQuery,
  useUpdateSavedStateMutation,
  useDeleteSavedStateMutation,
  useGetSavedStateQuery,
  useGetSavedStatesQuery,
  useSaveStateMutation,
  useGetIconQuery,
  useSaveIconMutation,
  useGetLocationNotesQuery,
  useGetLocationNetworkQuery,
  useSaveLocationNoteMutation,
  useDeleteLocationNoteMutation,
  useGetEntityNotesQuery,
  useSaveEntityNoteMutation,
  useDeleteEntityNoteMutation,
  useDeleteNotificationMutation,
  useGetLocationsQuery,
  useSeenNotificationMutation,
  useGetNotificationsQuery,
  useGetLocationBookmarksQuery,
  useSaveLocationBookmarkMutation,
  useDeleteLocationBookmarkMutation,
  useGetLocationVisitorsDailyQuery,
  useGetAudienceQuery,
  useGetFootfallHistogramForProjectQuery,
  useGetAudienceBookmarksQuery,
  useSaveAudienceBookmarkMutation,
  useDeleteAudienceBookmarkMutation,
  useCreateNewAlertMutation,
  useGetExistingAlertsQuery,
  useDeleteAlertMutation,
  useUpdateAlertMutation,
  useGetDateHistogramForAlertQuery,
  useGetClustersForAlertQuery,
  useGetEntityDwellForLocationsQuery,
  useGetEntityScoresQuery,
  useGetBoundsForEntitiesMutation,
  useTitoRequestMutation,
  useGetTackleboxHistoryQuery,
  useDeleteTackleboxHistoryMutation,
  useLazyGetTackleboxJobQuery,
  useGetEntityMutation,
  useGetLocationMutation,
  useCreateCampaignMutation,
  useGetCampaignsQuery,
  useToggleCampaignEnabledMutation,
  useDeleteCampaignMutation,
  useGetCampaignQuery,
  useGetTouchstoneStatsQuery,
  useLazyGetSignedUrlQuery
} = service;
