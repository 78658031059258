import type { PayloadAction, Slice } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Feature } from 'geojson';

import { RootState } from '@store';
import { TackleboxTools } from './const';
import { JobParams, MapViewport } from './types';

export interface SliceState {
  initialMapViewport: MapViewport;
  delayedMapViewport?: MapViewport;
  delayedMapBounds?: [[number, number], [number, number]];
  selectedTool?: string;
  polygons: Feature[];
  drawnPolygonSelected?: string;
  adminLayer: string | null;
  jobName: string;
  drawModeEnabled: boolean;
  minTitoPolygons: number;
  loadedJobId?: string;
}

const initialState: SliceState = {
  initialMapViewport: {
    latitude: 30,
    longitude: 0,
    zoom: 2
  },
  polygons: [],
  selectedTool: TackleboxTools.TITO,
  adminLayer: null,
  jobName: '',
  drawModeEnabled: false,
  minTitoPolygons: 2
};

export const tackleboxSlice: Slice<SliceState> = createSlice({
  name: 'tackleboxSlice',
  initialState,
  reducers: {
    setDrawnPolygonSelected: (state, action: PayloadAction<string>) => {
      state.drawnPolygonSelected = action.payload;
    },
    setDrawMode: (state, action: PayloadAction<boolean>) => {
      state.drawModeEnabled = action.payload;
    },
    setDelayedMapViewport: (state, action: PayloadAction<{ viewport: MapViewport; bounds: [[number, number], [number, number]] }>) => {
      state.delayedMapViewport = action.payload.viewport;
      state.delayedMapBounds = action.payload.bounds;
    },
    setSelectedTool: (state, action: PayloadAction<string>) => {
      state.selectedTool = action.payload;
    },
    setPolygons: (state, action: PayloadAction<Feature[]>) => {
      state.polygons = action.payload;
    },
    addPolygon: (state, action: PayloadAction<Feature>) => {
      state.polygons.push(action.payload);
    },
    resetParams: (state) => {
      state.loadedJobId = undefined;
      state.polygons = [];
      state.jobName = '';
      state.minTitoPolygons = 2;
    },
    setParams: (state, action: PayloadAction<JobParams>) => {
      const { features } = action.payload;
      state.loadedJobId = action.payload.tacklebox_job_id;
      state.polygons = features as Feature[];
      state.jobName = action.payload.name;
      state.minTitoPolygons = action.payload.min_polygons;
    },
    setAdminLayer: (state, action: PayloadAction<string>) => {
      state.adminLayer = action.payload;
    },
    setJobName: (state, action: PayloadAction<string>) => {
      state.jobName = action.payload;
    },
    setMinTitoPolygons: (state, action: PayloadAction<number>) => {
      state.minTitoPolygons = action.payload;
    }
  }
});

const selectInitialMapViewport = (store: RootState) => store.tackleboxSlice.initialMapViewport;
const selectDelayedMapViewport = (store: RootState) => store.tackleboxSlice.delayedMapViewport;
const selectDelayedMapBounds = (store: RootState) => store.tackleboxSlice.delayedMapBounds;
const selectSelectedTool = (store: RootState) => store.tackleboxSlice.selectedTool;
const selectLoadedJobId = (store: RootState) => store.tackleboxSlice.loadedJobId;
const selectPolygons = (store: RootState) => store.tackleboxSlice.polygons;
const selectAdminLayer = (store: RootState) => store.tackleboxSlice.adminLayer;
const selectJobName = (store: RootState) => store.tackleboxSlice.jobName;
const selectDrawModeEnabled = (store: RootState) => store.tackleboxSlice.drawModeEnabled;
const selectDrawnPolygonSelected = (store: RootState) => store.tackleboxSlice.drawnPolygonSelected;
const selectMinTitoPolygons = (store: RootState) => store.tackleboxSlice.minTitoPolygons;

const selectBasemap = (store: RootState) => store.slice.mapLayerType;

const stateForMap = createSelector(
  [selectInitialMapViewport, selectDelayedMapViewport, selectDelayedMapBounds, selectBasemap, selectPolygons, selectAdminLayer, selectDrawModeEnabled],
  (initialMapViewport, delayedMapViewport, delayedMapBounds, basemap, polygons, adminLayer, drawModeEnabled) => ({
    initialMapViewport,
    delayedMapViewport,
    delayedMapBounds,
    basemap,
    polygons,
    adminLayer,
    drawModeEnabled
  })
);

const stateForTool = createSelector(
  [selectSelectedTool, selectPolygons, selectJobName, selectDrawModeEnabled, selectDrawnPolygonSelected, selectAdminLayer, selectMinTitoPolygons],
  (selectedTool, polygons, jobName, drawModeEnabled, drawnPolygonSelected, adminLayer, minTitoPolygons) => ({
    selectedTool,
    polygons,
    jobName,
    drawModeEnabled,
    drawnPolygonSelected,
    adminLayer,
    minTitoPolygons
  })
);

const stateForHistory = createSelector([selectLoadedJobId], (loadedJobId) => ({
  loadedJobId
}));

export const sliceSelectors = {
  stateForMap,
  stateForTool,
  stateForHistory
};

// Action creators are generated for each case reducer function
export const {
  // \n
  setDrawnPolygonSelected,
  setDrawMode,
  setDelayedMapViewport,
  setSelectedTool,
  setPolygons,
  resetParams,
  setParams,
  addPolygon,
  setAdminLayer,
  setJobName,
  setMinTitoPolygons
} = tackleboxSlice.actions;

export default tackleboxSlice.reducer;
